// System
import { ChangeEvent, useEffect, useState } from "react";

// Third Party Type Imports
import {
  Button,
  Pagination,
  AlertColor,
  ClickAwayListener,
} from "@mui/material";
import { CgSortAz } from "react-icons/cg";
import { HiPlus } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// API
import { getUserChallenges } from "../../../api/challenge";

// Contexts
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";

// Components
import {
  ChallengeCard,
  ContentHeader,
  Filter,
  Spinner,
  XSnackbar,
} from "../../../components";

// Types
import { AuthContextType } from "../../../@types/authContext";
import { ChallengeInterface } from "../../../@types/challenge";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";

type LocationState = {
  showAlert?: boolean;
  alertType?: string;
  alertBody?: string;
};

const UserChallenges = () => {
  // Libraries
  const location = useLocation();

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Components State
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const begin = (currentPage - 1) * perPage;
  const end = begin + perPage;

  const [openFilter, setOpenFilter] = useState(false);
  const [industryChecked, setIndustryChecked] = useState<string[]>([]);
  const [disciplineChecked, setDisciplineChecked] = useState<string[]>([]);

  // Events
  const handleAlert = (showAlert: boolean) => {
    if (!showAlert) return;

    snackbar(
      (location.state as LocationState)?.alertType!,
      (location.state as LocationState)?.alertBody!
    );
  };

  const handleClick = () => {
    setOpenFilter((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenFilter(false);
  };

  const handleChangeIndustry = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setIndustryChecked((current) =>
        current.filter((industry) => {
          return industry !== e.target.value;
        })
      );
    } else {
      setIndustryChecked((prev) => [...prev, e.target.value]);
    }

    setCurrentPage(1);
    challenges.refetch();
  };

  const handleChangeDiscipline = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setDisciplineChecked((current) =>
        current.filter((discipline) => {
          return discipline !== e.target.value;
        })
      );
    } else {
      setDisciplineChecked((prev) => [...prev, e.target.value]);
    }

    setCurrentPage(1);
    challenges.refetch();
  };

  const handleClearFilter = () => {
    setIndustryChecked([]);
    setDisciplineChecked([]);

    challenges.refetch();
  };

  // Data Fetching
  const challenges = useQuery(
    ["challenges", auth?.id],
    () => getUserChallenges(auth?.id!),
    {
      onSuccess(data) {
        setCount(Math.ceil(data.data.length / perPage));
      },
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
      select(data) {
        const filteredData = data.data.filter((x: ChallengeInterface) => {
          const handleFilterIndustry = () => {
            if (industryChecked.length < 1) {
              return true;
            } else {
              let industryFiltered;
              let industryArr = x.industryGroupSector?.split(", ");
              industryArr?.forEach((value) => {
                industryFiltered = industryChecked.some(
                  (checked) => checked === value
                );
              });
              if (industryFiltered === true) {
                return true;
              }
            }
          };

          const handleFilterDiscipline = () => {
            if (disciplineChecked.length < 1) {
              return true;
            } else {
              let disciplineFiltered;
              let disciplineArr = x.disciplines?.split(", ");
              disciplineArr?.forEach((value) => {
                disciplineFiltered = disciplineChecked.some(
                  (checked) => checked === value
                );
              });
              if (disciplineFiltered === true) {
                return true;
              }
            }
          };

          if (handleFilterIndustry() && handleFilterDiscipline()) {
            return true;
          }
        });

        data.data = filteredData;

        return data;
      },
    }
  );

  // Effects
  useEffect(() => {
    handleAlert((location.state as LocationState)?.showAlert!);
  }, []);

  return (
    <>
      <div
        id="header"
        className="flex flex-col md:flex-row md:justify-between gap-y-4"
      >
        <div className="flex flex-col">
          <ContentHeader h1="My challenges" />
        </div>

        <div className="flex flex-row justify-between items-center gap-6">
          <Link to={"/challenges/add"} className="no-underline">
            <Button startIcon={<HiPlus />} className="btn btn-primary">
              Create new challenge
            </Button>
          </Link>

          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="relative">
              <Button
                onClick={handleClick}
                startIcon={<CgSortAz />}
                className="normal-case text-black-text font-[400px] text-[16px] leading-[20px] hover:bg-transparent hover:shadow-sm"
              >
                Filter by
              </Button>

              {openFilter ? (
                <Filter
                  industryChecked={industryChecked}
                  handleChangeIndustry={handleChangeIndustry}
                  disciplineChecked={disciplineChecked}
                  handleChangeDiscipline={handleChangeDiscipline}
                  handleClearFilter={handleClearFilter}
                />
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </div>
      {challenges.isLoading ? (
        <Spinner marginTop />
      ) : challenges.data?.data.length < 1 ? (
        <div className="flex flex-col w-full justify-center items-center mt-10">
          <p className="text text-gray-500 mt-4">No challenges posted</p>
          <div className="mt-2">
            <Link to={"/challenges/add"} className="mt-8 no-underline link">
              Create new challenge
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 xl:sm:grid-cols-4 gap-5">
            {challenges.data?.data
              .slice(begin, end)
              .map((challenge: ChallengeInterface) => (
                <ChallengeCard
                  key={challenge.id}
                  imageId={challenge.imageId}
                  id={challenge.id}
                  title={challenge.title}
                  state={challenge.state}
                  awardAmount={challenge.awardAmount}
                  userId={challenge.userId}
                  userName={challenge.userName}
                  endDate={challenge.endDate}
                  solutionCount={challenge.solutionCount}
                />
              ))}
          </div>

          {count > 1 && (
            <div className="mt-5 flex justify-start">
              <Pagination
                shape="rounded"
                count={count}
                onChange={(e, p) => {
                  setCurrentPage(p);
                }}
              />
            </div>
          )}
        </>
      )}

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default UserChallenges;
