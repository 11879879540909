// System
import React, { createContext, useContext, useState } from "react";

// Types
import { AuthContextType, AuthPropertyType } from "../@types/authContext";

type Props = {
  children: React.ReactNode;
};

// Constants
const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<AuthPropertyType>(null);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
