// Libraries
import Moment from "react-moment";

// Modules
import { BiLinkExternal } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// API
import {
  getUserNotifications,
  readNotification,
} from "../../../api/notification";

// Contexts
import { useAuthContext } from "../../../contexts/AuthContextProvider";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";

// Components
import { ContentHeader, Spinner } from "../../../components";

// Types
import { AuthContextType } from "../../../@types/authContext";
import { ErrorInterface } from "../../../@types/response";
import { NotificationInterface } from "../../../@types/notification";

const Notifications = () => {
  // Libraries
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;

  // Hooks
  const logout = useLogout();

  // Events
  const handleClickNotification = (
    id: string,
    link: string,
    isRead: boolean
  ) => {
    if (!isRead) {
      readNotificationMutation.mutate({
        userId: auth?.id!,
        notificationId: id,
      });
    }

    navigate(link);
  };

  // Mutation
  const readNotificationMutation = useMutation(readNotification, {
    onSuccess(data) {
      queryClient.invalidateQueries(["notifications", auth?.id]);
    },
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  // Data Fetching
  const notifications = useQuery(
    ["notifications", auth?.id],
    () => getUserNotifications({ userId: auth?.id! }),
    {
      onSuccess(data) {},
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
    }
  );

  return notifications.isLoading ? (
    <Spinner marginTop />
  ) : (
    <>
      <ContentHeader h1="Notifications" />
      <div className="mt-5">
        <div className="flex flex-row h-fit p-[17px] bg-white rounded shadow heading-card-title text-shadow-text">
          <div className="flex-initial basis-9/12">Notifications</div>
          <div className="flex-initial basis-2/12">Date</div>
          <div className="flex-none basis-1/12"></div>
        </div>

        {notifications.isLoading ? (
          <Spinner />
        ) : notifications.data?.data!.length < 1 ? (
          <div className="flex flex-col w-full justify-center items-center">
            <p className="text text-gray-500 mt-8">No new notifications</p>
          </div>
        ) : (
          notifications.data?.data!.map(
            (notification: NotificationInterface) => (
              <div
                key={notification.id}
                className="flex flex-row mt-[15px] h-fit p-[17px] bg-white rounded shadow heading-card-title text-black-text"
              >
                <div className="flex-initial basis-9/12">
                  {notification.message}
                </div>
                <div className="flex-initial basis-2/12">
                  <Moment format="MMMM DD, YYYY">
                    {notification.createdAt}
                  </Moment>
                </div>
                <div className="flex-none basis-1/12 text-right items-center">
                  <BiLinkExternal
                    className="text-black-text cursor-pointer"
                    onClick={() =>
                      handleClickNotification(
                        notification.id,
                        notification.link,
                        notification.isRead
                      )
                    }
                  />
                </div>
              </div>
            )
          )
        )}
      </div>
    </>
  );
};

export default Notifications;
