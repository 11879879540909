import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Input,
  InputAdornment,
  Rating,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik, FormikHelpers } from "formik";
import parse from "html-react-parser";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FaUserFriends, FaUsers } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { HiInformationCircle } from "react-icons/hi";
import { ImCancelCircle } from "react-icons/im";
import { RiUserStarLine } from "react-icons/ri";
import { Navigate, useParams } from "react-router-dom";

import { ALERT } from "../../../@consts/alert";
import { reviewedObjectType, reviewerType } from "../../../@consts/review";
import { ROLES } from "../../../@consts/roles";
import { solutionStates } from "../../../@consts/state";
import { containedButton } from "../../../@styles/containedButton";
import { outlinedButton } from "../../../@styles/outlinedButton";
import { AttachmentInterface } from "../../../@types/attachment";
import { AuthContextType } from "../../../@types/authContext";
import { CommentInterface } from "../../../@types/comment";
import { FileInterface } from "../../../@types/file";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";
import { getChallengeDetails } from "../../../api/challenge";
import { createNewComment, getComments } from "../../../api/comment";
import {
  createNewAttachment,
  createNewFile,
  getAttachments,
} from "../../../api/file";
import { editSolution, getSolutionDetails } from "../../../api/solution";
import {
  createNewFeedback,
  getSolutionFeedback,
} from "../../../api/solutionFeedback";
import { createNewReview, getReviewDetails } from "../../../api/userReview";
import {
  AttachmentCard,
  ChallengeHero,
  Comment,
  CommentEditor,
  CommonCard,
  CriteriaModal,
  Dropzone,
  Evaluation,
  HtmlTooltip,
  PrivateChatModal,
  Spinner,
} from "../../../components";
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";
import { formatToCurrency } from "../../../utils/currency";
import { getFileSize } from "../../../utils/file";
import { formatState } from "../../../utils/state";
import {
  CommentForm,
  CommentInitialValue,
  CommentSchema,
  FeedbackForm,
  FeedbackInitialValue,
  FeedbackSchema,
  ReviewForm,
  ReviewInitialValue,
  ReviewSchema,
} from "./consts/schemas";

const Solution = () => {
  // Libraries
  let params = useParams();

  // Constants
  const solutionId = params.solutionId;

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Components State
  const [openRejectForm, setOpenRejectForm] = useState(false);
  const [openEvaluationForm, setOpenEvaluationForm] = useState(false);
  const [openAwardForm, setOpenAwardForm] = useState(false);
  const [openReviewForm, setOpenReviewForm] = useState(false);
  const [openCommentForm, setOpenCommentForm] = useState(true);

  // Data State
  const [solutionState, setSolutionState] = useState("");
  const [isUserChallenge, setIsUserChallenge] = useState(false);
  const [commentField, setCommentField] = useState("");
  const [commentAttachments, setCommentAttachments] = useState<FileInterface[]>(
    [],
  );
  const [isReviewed, setIsReviewed] = useState<boolean | null>(null);
  const [openCriteria, setOpenCriteria] = useState(false);
  const [openPrivateChat, setOpenPrivateChat] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);

  const commentsEndRef = useRef<null | HTMLDivElement>(null);

  // Events
  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onDropFile = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);

      uploadAttachmentMutation.mutate(fd);
    });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      const errors: string[] = file.errors.map(({ message }) => message);

      const errorMessage: string = `${file.file.name} rejected. ${errors.join(
        ", ",
      )}`;

      snackbar(ALERT.error, errorMessage);
    });
  }, []);

  const removeSelectedAttachment = (id: string) => {
    setCommentAttachments((current) =>
      current.filter((file) => {
        return file.id !== id;
      }),
    );
  };

  const handleSubmitAttachments = (objectId: string) => {
    commentAttachments.map((attachment) => {
      submitAttachmentMutation.mutate({
        objectId,
        objectType: "COMMENT",
        fileId: attachment.id,
      });
    });
  };

  const openSendToOption = () => {
    const result = solution.data?.data.userId !== auth?.id;

    return result;
  };

  const handleSubmittedSolution = (
    isUserChallenge: boolean,
    solutionState: string,
  ) => {
    if (isUserChallenge && solutionState === solutionStates.submitted) {
      updateSolutionMutation.mutate({
        id: solution.data?.data.id,
        title: solution.data?.data.title,
        details: solution.data?.data.details,
        state: solutionStates.underReview,
        challengeId: solution.data?.data.challengeId,
      });
    }
  };

  const handleModalSolutionState = () => {
    if (openRejectForm) {
      return solutionStates.rejected;
    } else if (openEvaluationForm) {
      return solutionStates.underEvaluation;
    } else if (openAwardForm) {
      return solutionStates.awarded;
    } else {
      return "";
    }
  };

  const handleAdminPrevilege = (authRole: string) => {
    if (authRole === ROLES.ADMIN) {
      setOpenCommentForm(false);
      // setOpenProposedSolution(true);
    }
  };

  // Form Submit
  const submitComment = async (
    values: CommentForm,
    actions: FormikHelpers<CommentForm>,
  ) => {
    if (values.comment === "<p><br></p>") return;

    const hasFile = commentAttachments.length > 0 ? true : false;

    submitCommentMutation.mutate({
      ...values,
      solutionId,
      hasFile,
    });

    actions.setSubmitting(false);
  };

  const submitFeedback = async (
    values: FeedbackForm,
    actions: FormikHelpers<FeedbackForm>,
  ) => {
    submitFeedbackMutation.mutate(values);

    setOpenRejectForm(false);
    setOpenEvaluationForm(false);
    setOpenAwardForm(false);

    actions.setSubmitting(false);
  };

  const submitReview = async (
    values: ReviewForm,
    actions: FormikHelpers<ReviewForm>,
  ) => {
    submitReviewMutation.mutate(values);

    setOpenReviewForm(false);

    actions.setSubmitting(false);
  };

  // Mutation
  const submitCommentMutation = useMutation(createNewComment, {
    onSuccess(data) {
      setCommentField("");

      comments.refetch();

      handleSubmitAttachments(data.data.id);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const uploadAttachmentMutation = useMutation(createNewFile, {
    onSuccess(data, variables) {
      const file = variables.get("file") as File;

      const attachment = {
        name: file.name,
        fileType: file.type.split("/")[1],
        size: getFileSize(file.size),
        id: data.data.dataValues.id,
      };

      setCommentAttachments((prev) => [...prev, { ...attachment }]);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const submitAttachmentMutation = useMutation(createNewAttachment, {
    onSuccess(data, variables, context) {
      setCommentAttachments([]);
    },
    onError(error, variables, context) {},
  });

  const updateSolutionMutation = useMutation(editSolution, {
    onSuccess(data) {
      solution.refetch();
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const submitFeedbackMutation = useMutation(createNewFeedback, {
    onSuccess(data) {
      solution.refetch();
      feedback.refetch();
      checkIsReviewed.refetch();

      if (
        data.data.state === solutionStates.awarded ||
        data.data.state === solutionStates.rejected
      ) {
        setOpenReviewForm(true);
      }

      handleAlert(ALERT.success, "Solution status has been updated");
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const submitReviewMutation = useMutation(createNewReview, {
    onSuccess(data) {
      checkIsReviewed.refetch();

      handleAlert(ALERT.success, "Review successfully submitted");
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  // Data Fetching
  const solution = useQuery(
    ["solution", solutionId],
    () => getSolutionDetails(solutionId!),
    {
      onSuccess(data) {
        setSolutionState(data.data.state);
      },
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
    },
  );

  const challengeId = solution.data?.data.challengeId;

  const challenge = useQuery(
    ["challenge", challengeId],
    () => getChallengeDetails(challengeId!),
    {
      onSuccess(data) {
        if (data.data.userId === auth?.id) {
          setIsUserChallenge(true);
        }
      },
      enabled: !!challengeId,
    },
  );

  const attachments = useQuery(["solution-attachments", solutionId], () =>
    getAttachments(solutionId!),
  );

  const comments = useQuery(
    ["solution-comments", { solutionId }],
    () => getComments(solutionId!),
    { cacheTime: 0 },
  );

  const feedback = useQuery(
    ["solution-feedback", solutionId],
    () => getSolutionFeedback(solutionId!),
    {
      onSuccess() {
        checkIsReviewed.refetch();
      },
    },
  );

  const feedbackId = solution.data?.data.id;

  const checkIsReviewed = useQuery(
    ["isReviewed", isUserChallenge ? solutionId : challengeId],
    () => getReviewDetails(isUserChallenge ? solutionId : challengeId),
    {
      onSuccess(data) {
        if (
          data.data.isReviewed === false &&
          (feedback.data?.data.solutionState === solutionStates.awarded ||
            feedback.data?.data.solutionState === solutionStates.rejected)
        ) {
          setIsReviewed(false);
        } else {
          setIsReviewed(true);
        }
      },
      enabled: !!feedbackId,
    },
  );

  useEffect(() => {
    scrollToBottom();
    auth?.role && handleAdminPrevilege(auth?.role);
  }, [comments]);

  useEffect(() => {
    handleSubmittedSolution(isUserChallenge, solutionState);
  }, [solutionState, isUserChallenge]);

  // JSX
  if (challenge.isLoading) {
    return <Spinner />;
  }

  if (solution.data?.data.state === solutionStates.draft) {
    return (
      <Navigate
        to={`/solutions/${solution.data.data.id}/edit`}
        state={{ challengeId: solution.data?.data.challengeId }}
      />
    );
  }

  return (
    <>
      <ChallengeHero
        id={challenge.data?.data.id}
        title={challenge.data?.data.title}
        state={challenge.data?.data.state}
        challengeId={challenge.data?.data.id}
      />

      <div className="flex flex-col justify-between gap-5 xl:flex-row">
        <div className="basis-3/4">
          <div className="flex flex-col">
            <CommonCard title="Proposed solution">
              <h2>{solution.data?.data.title}</h2>
              <p className="mt-5">{parse(solution.data?.data.details)}</p>
            </CommonCard>

            <div className="mt-5 grid w-full grid-cols-1 divide-y divide-shadow-text rounded bg-white shadow">
              <div id="headerCard" className="px-7 pt-6 pb-2">
                <h3>Comments</h3>
              </div>
              <div
                id="headerBody"
                className="flex h-80 flex-col gap-4 overflow-auto p-7 pt-6 "
              >
                {!comments.data?.data.length ? (
                  <div className="flex h-full w-full items-center justify-center text-shadow-text">
                    No comments yet
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col gap-5">
                      {comments.data?.data.map((comment: CommentInterface) => (
                        <Comment
                          id={comment.id}
                          userId={comment.userId}
                          userName={comment.userName}
                          body={comment.comment}
                          createdAt={comment.createdAt}
                          hasFile={comment.hasFile}
                          key={comment.id}
                          isPrivate={comment.isPrivate}
                        />
                      ))}
                    </div>
                  </>
                )}
                <div ref={commentsEndRef} />
              </div>
              {openCommentForm && (
                <div className="text flex flex-col gap-4 p-7">
                  <Formik
                    initialValues={CommentInitialValue}
                    validationSchema={CommentSchema}
                    onSubmit={submitComment}
                    validateOnMount={true}
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit} autoComplete="off">
                        {openSendToOption() && (
                          <div className="mb-4 flex flex-row items-center gap-3">
                            <Switch
                              id="isPrivate"
                              name="isPrivate"
                              size="small"
                              checked={props.values.isPrivate}
                              onChange={props.handleChange}
                            />

                            <div className="flex flex-col">
                              {props.values.isPrivate ? (
                                <span className="flex flex-row items-center gap-2">
                                  <FaUserFriends /> Private
                                </span>
                              ) : (
                                <span className="flex flex-row items-center gap-2">
                                  <FaUsers /> Public
                                </span>
                              )}
                              <p className="micro-copy flex flex-row items-center gap-1 text-[12px]">
                                <HtmlTooltip
                                  placement="right"
                                  title={
                                    <>
                                      <p>
                                        <b>Public</b> : Comment will be send to
                                        all paticipant in this solution
                                      </p>
                                      <p>
                                        <b>Private</b> : Comment only send to
                                        owner and evaluator
                                      </p>
                                    </>
                                  }
                                >
                                  <HiInformationCircle className="cursor-pointer text-lg" />
                                </HtmlTooltip>
                                Set your comment visibility
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="rounded border border-shadow-text">
                          <CommentEditor
                            setContents={commentField}
                            onChange={(context) => {
                              setCommentField(context);
                              props.setFieldValue("comment", context);
                            }}
                          />
                        </div>

                        <div className="mt-6 flex h-fit flex-col rounded-[3px] border border-slate-300 p-3">
                          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            {commentAttachments.map((file) => (
                              <div
                                key={file.id}
                                className="flex flex-row items-center justify-between rounded border border-slate-200 p-2 pr-5"
                              >
                                <div className="flex flex-row justify-start gap-3">
                                  <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                                    {file.fileType}
                                  </div>
                                  <div className="flex flex-col">
                                    <p className="heading-card-title text-black-text">
                                      {file.name}
                                    </p>
                                    <span className="micro-copy text-shadow-text">
                                      {file.size}
                                    </span>
                                  </div>
                                </div>
                                <BiTrash
                                  onClick={() => {
                                    removeSelectedAttachment(file.id);
                                  }}
                                  className="cursor-pointer text-xl hover:text-red-alert"
                                />
                              </div>
                            ))}

                            <Dropzone
                              multiple={true}
                              maxSize={10000000}
                              onDrop={onDropFile}
                              onDropRejected={onDropRejected}
                            >
                              <div className="flex cursor-pointer flex-row items-center justify-between rounded border-2 border-dashed border-shadow-text p-2 pr-5 shadow hover:border-blue-light hover:text-blue-light">
                                <div className="flex flex-row items-center justify-start gap-3">
                                  <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                                    pdf
                                  </div>
                                  <div className="flex flex-col">
                                    <p className="heading-card-title text-black-text">
                                      Drop files here
                                    </p>
                                    <span className="micro-copy text-shadow-text">
                                      Supported files: docx, pptx, pdf, jpg or
                                      png, maximum file size is 10 MB.
                                    </span>
                                  </div>
                                </div>
                                <AiOutlineCloudUpload className="text-2xl" />
                              </div>
                            </Dropzone>
                          </div>
                        </div>
                        <Button type="submit" className="btn btn-primary mt-8">
                          Submit comment
                        </Button>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="basis-1/4">
          <CommonCard title="File attachments">
            <div className="grid grid-cols-1 gap-5">
              {attachments.data?.data.map((attachment: AttachmentInterface) => (
                <AttachmentCard
                  key={attachment.id}
                  id={attachment.fileId}
                  name={attachment.fileName}
                />
              ))}
            </div>
          </CommonCard>

          {solution.data?.data.state !== solutionStates.submitted && (
            <CommonCard title="Progress State">
              <p className="text-hint mb-2 text-shadow-text">State</p>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row items-center gap-3">
                  {solution.data?.data.state === solutionStates.rejected ? (
                    <>
                      <ImCancelCircle className="text-red-alert" />
                      <span>{formatState(solution.data?.data.state)}</span>
                    </>
                  ) : (
                    <>
                      <FiCheckCircle className="text-green-alt" />
                      <span>{formatState(solution.data?.data.state)}</span>
                    </>
                  )}
                </div>

                {isUserChallenge &&
                  solution.data?.data.state !== solutionStates.awarded &&
                  solution.data?.data.state !== solutionStates.rejected && (
                    <>
                      <div className="flex flex-row items-center justify-between gap-4">
                        {solution.data?.data.state ===
                          solutionStates.underReview && (
                          <Button
                            className="btn btn-primary w-full"
                            onClick={() =>
                              setOpenEvaluationForm(!openEvaluationForm)
                            }
                          >
                            Evaluate
                          </Button>
                        )}
                        {solution.data?.data.state ===
                          solutionStates.underEvaluation && (
                          <Button
                            className="btn btn-primary w-full"
                            onClick={() => setOpenAwardForm(!openAwardForm)}
                          >
                            Award
                          </Button>
                        )}
                        {solution.data?.data.state !== solutionStates.awarded &&
                          solution.data?.data.state !==
                            solutionStates.rejected && (
                            <Button
                              className="btn btn-alert-outline w-full"
                              onClick={() => setOpenRejectForm(!openRejectForm)}
                            >
                              Reject
                            </Button>
                          )}
                      </div>
                      <Button
                        className="btn w-full bg-[#F79009]"
                        onClick={() => setOpenEvaluation(!openEvaluation)}
                      >
                        View Evaluation
                      </Button>
                    </>
                  )}
              </div>

              <div className="flex flex-col px-1">
                {feedback.data?.data && (
                  <>
                    {feedback.data?.data.awardAmount && (
                      <>
                        <p className="text-hint mt-5 text-shadow-text">
                          Awarded
                        </p>
                        <h3 className="mt-1 text-green-alt">
                          {formatToCurrency(feedback.data?.data.awardAmount)}
                        </h3>
                      </>
                    )}
                    <p className="text-hint mt-5 text-shadow-text">Feedback</p>
                    <p className="text-hint mt-1">
                      {feedback.data?.data.userComment}
                    </p>
                  </>
                )}
              </div>
            </CommonCard>
          )}

          {isReviewed === false && (
            <Button
              className="btn btn-primary mt-5 w-full"
              onClick={() => setOpenReviewForm(!openReviewForm)}
            >
              <RiUserStarLine className="mr-2" />
              Submit review
            </Button>
          )}

          {auth?.role === ROLES.EVALUATOR && (
            <CommonCard title="">
              <div className="flex flex-col gap-y-5">
                <Button
                  className="btn btn-primary w-full"
                  onClick={() => setOpenCriteria(!openCriteria)}
                >
                  Evaluate
                </Button>
                <Button
                  className="btn w-full bg-[#49994D]"
                  onClick={() => setOpenPrivateChat(!openPrivateChat)}
                >
                  Interact Challange Owner
                </Button>
              </div>
            </CommonCard>
          )}
        </div>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={
          {
            ...ReviewInitialValue,
            userId: isUserChallenge
              ? solution.data?.data.userId
              : challenge.data?.data.userId,
            reviewerId: auth?.id,
            reviewerType: isUserChallenge
              ? reviewerType.SEEKER
              : reviewerType.SOLVER,
            reviewedObjectId: isUserChallenge
              ? solution.data?.data.id
              : challenge.data?.data.id,
            reviewedObjectType: isUserChallenge
              ? reviewedObjectType.SOLUTION
              : reviewedObjectType.CHALLENGE,
          } as ReviewForm
        }
        validationSchema={ReviewSchema}
        onSubmit={submitReview}
        validateOnMount={true}
      >
        {(props) => (
          <Dialog
            open={openReviewForm}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <DialogTitle id="alert-dialog-title">
                <h2>User Review</h2>
              </DialogTitle>
              <DialogContent
                dividers={true}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="rating" style={{ marginBottom: "16px" }}>
                  Review
                  <span style={{ color: "#E68552" }}>*</span>
                </label>
                <Rating
                  name="no-value"
                  value={props.values.rating}
                  onChange={(e, value) => {
                    props.setFieldValue("rating", value ?? 0);
                  }}
                />
                {props.errors.rating && props.touched.rating && (
                  <FormHelperText error>{props.errors.rating}</FormHelperText>
                )}
                <label
                  htmlFor="review"
                  style={{ marginTop: "16px", marginBottom: "8px" }}
                >
                  Review
                  <span style={{ color: "#E68552" }}>*</span>
                </label>
                <TextField
                  value={props.values.review}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.errors.review && props.touched.review ? true : false
                  }
                  helperText={
                    props.errors.review && props.touched.review
                      ? props.errors.review
                      : ""
                  }
                  id="review"
                  name="review"
                  multiline
                  rows={3}
                  variant="outlined"
                  placeholder=""
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenReviewForm(false);
                  }}
                  variant="outlined"
                  sx={outlinedButton}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={containedButton}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Formik>

      <Formik
        enableReinitialize={true}
        initialValues={
          {
            ...FeedbackInitialValue,
            solutionId: solutionId,
            solutionState: handleModalSolutionState(),
          } as FeedbackForm
        }
        validationSchema={FeedbackSchema}
        onSubmit={submitFeedback}
        validateOnMount={true}
      >
        {(props) => (
          <Dialog
            open={openRejectForm || openEvaluationForm || openAwardForm}
            onClose={() => {
              setOpenRejectForm(false);
              setOpenEvaluationForm(false);
              setOpenAwardForm(false);
            }}
            fullWidth
            // maxWidth={"xs"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <DialogTitle id="alert-dialog-title">
                <h2>Progress Feedback</h2>
              </DialogTitle>
              <DialogContent
                dividers={true}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="userComment" style={{ marginBottom: "16px" }}>
                  Reason
                  <span style={{ color: "#E68552" }}>*</span>
                </label>
                <TextField
                  value={props.values.userComment}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.errors.userComment && props.touched.userComment
                      ? true
                      : false
                  }
                  helperText={
                    props.errors.userComment && props.touched.userComment
                      ? props.errors.userComment
                      : ""
                  }
                  id="userComment"
                  name="userComment"
                  multiline
                  rows={3}
                  variant="outlined"
                  placeholder=""
                />
                {openAwardForm && (
                  <>
                    <label
                      htmlFor="awardAmount"
                      style={{ marginTop: "16px", marginBottom: "8px" }}
                    >
                      Reward
                      <span style={{ color: "#E68552" }}>*</span>
                    </label>
                    <Input
                      value={props.values.awardAmount}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.errors.awardAmount && props.touched.awardAmount
                          ? true
                          : false
                      }
                      id="awardAmount"
                      name="awardAmount"
                      type="number"
                      inputProps={{ max: challenge.data?.data.awardAmount }}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          style={{ color: "#323A46" }}
                        >
                          <span>(US)</span>
                          <span style={{ marginLeft: "4px" }}>$</span>
                        </InputAdornment>
                      }
                    />
                    {props.errors.awardAmount && props.touched.awardAmount && (
                      <FormHelperText error>
                        {props.errors.awardAmount}
                      </FormHelperText>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenRejectForm(false);
                    setOpenEvaluationForm(false);
                    setOpenAwardForm(false);
                  }}
                  variant="outlined"
                  sx={outlinedButton}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={containedButton}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(e) => {
          setOpenSnackbar(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity={alertType as AlertColor}>{alertBody}</Alert>
      </Snackbar>

      {auth?.role === ROLES.EVALUATOR && (
        <>
          <CriteriaModal
            open={openCriteria}
            onClose={() => {
              setOpenCriteria(false);
            }}
            solutionId={solutionId ?? ""}
            challengeCriterias={challenge.data?.data.criterias}
          />

          <PrivateChatModal
            open={openPrivateChat}
            onClose={() => {
              setOpenPrivateChat(false);
            }}
            solutionId={solutionId ?? ""}
          />
        </>
      )}

      {isUserChallenge && (
        <Evaluation
          open={openEvaluation}
          onClose={() => {
            setOpenEvaluation(false);
          }}
          solutionId={solutionId ?? ""}
        />
      )}
    </>
  );
};

export default Solution;
