// System
import { useEffect } from "react";

// Third Parties
import Moment from "react-moment";

// Third Party Type Imports
import { AlertColor, Button } from "@mui/material";
import { CgSortAz } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// API
import { getUserSolutions } from "../../../api/solution";

// Contexts
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";

// Components
import {
  ContentHeader,
  Spinner,
  UserSolutionRow,
  XSnackbar,
} from "../../../components";

// Types
import { AuthContextType } from "../../../@types/authContext";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";
import { SolutionInterface } from "../../../@types/solution";

type LocationState = {
  showAlert?: boolean;
  alertType?: string;
  alertBody?: string;
};

const UserSolutions = () => {
  // Libraries
  const location = useLocation();

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Events
  const handleAlert = (showAlert: boolean) => {
    if (!showAlert) return;

    snackbar(
      (location.state as LocationState)?.alertType!,
      (location.state as LocationState)?.alertBody!
    );
  };

  // Data Fetching
  const solutions = useQuery(
    ["user-solutions", auth?.id],
    () => getUserSolutions(auth?.id!),
    {
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
    }
  );

  // Effects
  useEffect(() => {
    handleAlert((location.state as LocationState)?.showAlert!);
  }, []);

  return (
    <>
      <div
        id="header"
        className="flex flex-col md:flex-row md:justify-between gap-y-4"
      >
        <div className="flex flex-col">
          <ContentHeader h1="My solutions" />
        </div>
        <div className="flex items-center">
          <Button
            startIcon={<CgSortAz />}
            className="font-telegraf normal-case text-black-text font-[400px] text-[16px] leading-[20px] hover:bg-transparent hover:shadow-sm"
          >
            Filter by
          </Button>
        </div>
      </div>

      <div id="solutionsSection" className="mt-5">
        <div className="flex flex-row h-fit p-[17px] bg-white rounded shadow heading-card-title text-shadow-text">
          <div className="flex-initial basis-8/12">Solution title</div>
          <div className="flex-initial basis-2/12">Submitted date</div>
          <div className="flex-initial basis-1/12">Status</div>
          <div className="flex-none basis-1/12"></div>
        </div>

        {solutions.isLoading ? (
          <Spinner marginTop />
        ) : solutions.data != undefined && solutions.data.data.length < 1 ? (
          <div className="flex flex-col w-full justify-center items-center">
            <p className="text text-gray-500 mt-8">No solutions submitted</p>
          </div>
        ) : (
          solutions.data?.data.map((solution: SolutionInterface) => (
            <UserSolutionRow
              key={solution.id}
              id={solution.id}
              title={solution.title}
              userId={solution.userId}
              challengeId={solution.challengeId}
              createdAt={solution.createdAt}
              state={solution.state}
            />
          ))
        )}
      </div>

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default UserSolutions;
