// System
import { useEffect, useState } from "react";

// Modules
import { AiOutlineClockCircle } from "react-icons/ai";
import { CgCheckR } from "react-icons/cg";
import { useMutation } from "@tanstack/react-query";
import { RiMailSendLine } from "react-icons/ri";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// API
import { resendEvaluatorInvitation } from "../../api/challenge";

// Custom Hooks
import useLogout from "../../hooks/useLogout";
import useSnackbar from "../../hooks/useSnackbar";

// Components
import { XAvatar } from "../../components";

// Styles
import { outlinedButton } from "../../@styles/outlinedButton";
import { containedButton } from "../../@styles/containedButton";

// Utils
import { daysRemaining } from "../../utils/moment";

// Constants
import { ALERT } from "../../@consts/alert";
import { defaultSuccess } from "../../@consts/response";

// Types
import { ErrorInterface } from "../../@types/response";

type ChallengeEvaluatorsType = {
  userId?: string;
  userEmail: string;
};

type Props = {
  challengeId: string;
  postedId: string;
  postedBy: string;
  postedByDisciplies: string;
  award: string;
  endDate: string;
  isUserChallenge: boolean;
  isPublished: boolean;
  challengeEvaluators?: ChallengeEvaluatorsType[];
};

const ChallengeAttributes = ({
  challengeId,
  postedId,
  postedBy,
  postedByDisciplies,
  award,
  endDate,
  isUserChallenge,
  isPublished,
  challengeEvaluators,
}: Props) => {
  // Data State
  const [resendEvaluator, setResendEvaluator] = useState<{
    evaluatorEmail: string;
    evaluatorId?: string;
  } | null>(null);

  // Component State
  const [daysColor, setDaysColor] = useState("text-red-alert");
  const [resendConfirmation, setResendConfirmation] = useState(false);

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Events
  const handleDaysColor = (days: number) => {
    if (days >= 60) {
      setDaysColor("text-green-alt");
    } else if (days < 60 && days >= 4) {
      setDaysColor("text-blue-mid");
    }
  };

  const resendInvitation = (challengeId: string, evaluatorEmail: string) => {
    resendInvitationMutation.mutate({
      id: challengeId,
      email: evaluatorEmail,
    });
  };

  const handleResendInvitation = (
    evaluatorEmail: string,
    evaluatorId?: string
  ) => {
    if (evaluatorId) return;

    setResendEvaluator({ evaluatorEmail, evaluatorId });

    setResendConfirmation(!resendConfirmation);
  };

  const handleConfirmResend = () => {
    setResendConfirmation(!resendConfirmation);

    if (!resendEvaluator) return;

    resendInvitation(challengeId, resendEvaluator?.evaluatorEmail);
  };

  // Mutation
  const resendInvitationMutation = useMutation(resendEvaluatorInvitation, {
    onSuccess(data) {
      const alertType = ALERT.success;
      const alertBody = data.data.message ?? defaultSuccess;

      snackbar(alertType, alertBody);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      snackbar(alertType, alertBody);
    },
  });
  // Effects
  useEffect(() => {
    handleDaysColor(daysRemaining(endDate));
  }, []);

  return (
    <>
      <p className="text-hint text-shadow-text">Posted by</p>
      <div className="flex flex-row items-center heading-card-title text-black-text mt-3">
        <XAvatar id={postedId} name={postedBy} size={47} fontSize={16} />
        <div className="flex flex-col">
          {postedBy}
          <span className="text-hint">{postedByDisciplies}</span>
        </div>
      </div>
      <p className="text-hint text-shadow-text mt-5">Award</p>
      <h3 className="text-green-alt mt-1">{award}</h3>
      <p className="text-hint text-shadow-text mt-5">Latest submission</p>
      <div className={`flex flex-row mt-1 items-center ${daysColor}`}>
        <AiOutlineClockCircle className="mr-2" />
        {daysRemaining(endDate)} days left
      </div>
      {isUserChallenge && isPublished && (
        <>
          <p className="text-hint text-shadow-text mt-5">Evaluators</p>
          <div className="mt-2 flex flex-col gap-1 text-hint">
            {!challengeEvaluators?.length ? (
              <span className="text-shadow-text ">
                Don't have assigned evaluators
              </span>
            ) : (
              <>
                {challengeEvaluators?.map((evaluator) => (
                  <div
                    className={`flex flex-row justify-between items-center ${
                      evaluator.userId
                        ? ""
                        : "cursor-pointer hover:text-blue-light"
                    }`}
                  >
                    <div
                      className="flex flex-row gap-3 items-center"
                      onClick={() =>
                        handleResendInvitation(
                          evaluator.userEmail,
                          evaluator.userId
                        )
                      }
                    >
                      {evaluator.userId ? (
                        <CgCheckR className="mt-1" />
                      ) : (
                        <RiMailSendLine className="mt-1" />
                      )}
                      {evaluator.userEmail}
                    </div>
                    <div>
                      {evaluator.userId ? "(Registered)" : "(Unregistered)"}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}

      <Dialog
        open={resendConfirmation}
        onClose={() => setResendConfirmation(!resendConfirmation)}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Resend Invitation to evaluator
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to resend invitation evaluator to{" "}
            {resendEvaluator?.evaluatorEmail}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setResendConfirmation(!resendConfirmation)}
            variant="outlined"
            sx={outlinedButton}
          >
            No
          </Button>
          <Button
            variant="contained"
            sx={containedButton}
            onClick={() => handleConfirmResend()}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChallengeAttributes;
