// Third Party Types
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  // Libraries
  const navigate = useNavigate();

  // JSX
  return (
    <div className="flex flex-col w-full h-screen justify-center items-center">
      <div className="flex flex-col w-fit h-fit">
        <p className="text-black-text font-black text-5xl">404 Not Found</p>
        <div className="flex w-full justify-end mt-3">
          <div
            onClick={() => navigate(-1)}
            className="no-underline text-blue-light hover:text-blue-deep flex items-center cursor-pointer"
          >
            <IoMdArrowRoundBack className="mb-1 mr-[2px] text-xl" />
            Go back
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
