// System
import { useState, useEffect } from "react";

// Third Party Type Imports
import { Outlet } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

// Custom Third Party Libraries
import { getUserDetails } from "../../api/user";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";

// Components
import { Spinner } from "../../components";

// Types
import { AuthContextType, AuthPropertyType } from "../../@types/authContext";

const PersistLoginRoute = () => {
  // Libraries
  const queryClient = new QueryClient();

  // Constants
  const userId = localStorage.getItem("userId") || "null";
  const accessToken = localStorage.getItem("accessToken") || "null";

  // Contexts
  const { auth, setAuth } = useAuthContext() as AuthContextType;

  // UI State
  const [isLoading, setIsLoading] = useState(true);

  // Events
  const verifyAuth = async () => {
    try {
      const user = await queryClient.fetchQuery(["user", userId], async () =>
        getUserDetails(userId)
      );

      const newAuth: AuthPropertyType = {
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        role: user.data.role,
        country: user.data.country,
        id: user.data.id,
        accessToken: accessToken,
      };

      setAuth(newAuth);
    } catch (error) {
      setAuth(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    const setNewAuth = async () => {
      await verifyAuth();
    };

    !auth ? setNewAuth() : setIsLoading(false);
  }, []);

  // JSX;
  return isLoading ? <Spinner full /> : <Outlet />;
};

export default PersistLoginRoute;
