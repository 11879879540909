import { Outlet } from "react-router-dom";

import { AuthentificationSideBackground } from "../../components";

const AuthentificationLayout = () => {
  return (
    <div className="flex w-full flex-row">
      <div className="hidden basis-1/2 md:flex">
        <AuthentificationSideBackground />
      </div>
      <div className="bg-[E5E5E5] h-screen basis-full overflow-auto p-12 md:basis-1/2">
        <div className="grid h-full grid-cols-1 items-center">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthentificationLayout;
