import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ROLES } from "./@consts/roles";
import { AuthentificationLayout, HomeLayout, MainLayout } from "./layouts";
import {
  AddChallenge,
  AddSolution,
  AdminChallenges,
  AdminUsers,
  Challenge,
  Challenges,
  Dashboard,
  EvaluatorChallenges,
  ForgotPassword,
  Home,
  InvitedEvaluator,
  NotFound,
  Notifications,
  Profiles,
  Register,
  SignIn,
  Solution,
  Ui,
  Unauthorized,
  UserChallenges,
  UserSolutions,
  VerifyUser,
} from "./pages";
import { AuthentificationRoute, PersistLoginRoute } from "./routes";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/unathorized" element={<Unauthorized />} />
        <Route path="/ui" element={<Ui />} />

        <Route path="/redirect">
          <Route index element={<NotFound />} />
          <Route path="invited-evaluator" element={<InvitedEvaluator />} />
          <Route path="verify-user" element={<VerifyUser />} />
        </Route>

        <Route path="/" element={<AuthentificationLayout />}>
          <Route path="login" element={<SignIn />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route element={<PersistLoginRoute />}>
          <Route
            element={
              <AuthentificationRoute
                allowedRole={[ROLES.SEEKER, ROLES.EVALUATOR, ROLES.ADMIN]}
              />
            }
          >
            <Route path="/" element={<MainLayout />}>
              {/* <Route index element={<Dashboard />} /> */}
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="challenges">
                <Route index element={<Challenges />} />
                <Route path="add" element={<AddChallenge />} />
                <Route path=":challengeId">
                  <Route index element={<Challenge />} />
                  <Route path="edit" element={<AddChallenge />} />
                  <Route path="solutions">
                    <Route path="add" element={<AddSolution />} />
                  </Route>
                </Route>
              </Route>
              <Route path="pavilions">
                <Route path=":country" element={<Challenges />} />
              </Route>

              <Route path="solutions">
                <Route path=":solutionId">
                  <Route index element={<Solution />} />
                  <Route path="edit" element={<AddSolution />} />
                </Route>
              </Route>

              <Route path="user">
                <Route path="challenges">
                  <Route index element={<UserChallenges />} />
                </Route>
                <Route path="solutions">
                  <Route index element={<UserSolutions />} />
                </Route>
                <Route path="notifications">
                  <Route index element={<Notifications />} />
                </Route>
                <Route path="profiles">
                  <Route index element={<Profiles />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            element={<AuthentificationRoute allowedRole={[ROLES.ADMIN]} />}
          >
            <Route path="/" element={<MainLayout />}>
              <Route path="admin">
                <Route path="challenges">
                  <Route index element={<AdminChallenges />} />
                </Route>
                <Route path="users">
                  <Route index element={<AdminUsers />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            element={<AuthentificationRoute allowedRole={[ROLES.EVALUATOR]} />}
          >
            <Route path="/" element={<MainLayout />}>
              <Route path="evaluator">
                <Route path="challenges">
                  <Route index element={<EvaluatorChallenges />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
